import React, { useState, useRef } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/database";
import { ReactComponent as HangupIcon } from "./icons/hangup.svg";
import { ReactComponent as MoreIcon } from "./icons/more-vertical.svg";
import { ReactComponent as CopyIcon } from "./icons/copy.svg";
import { useParams } from "react-router-dom";

function Videos({ callId, setPage }) {
  const [webcamActive, setWebcamActive] = useState(false);
  const [roomId, setRoomId] = useState(callId);
  const { joinCode } = useParams();
  var mode = "create";

  console.log(joinCode);
  //   setRoomId(joinCode);

  const localRef = useRef();
  const remoteRef = useRef();

  // Initialize WebRTC and Firebase Realtime Database
  const servers = {
    iceServers: [
      {
        urls: [
          "stun:stun1.l.google.com:19302",
          "stun:stun2.l.google.com:19302",
        ],
      },
    ],
    iceCandidatePoolSize: 10,
  };

  const pc = new RTCPeerConnection(servers);
  const database = firebase.database();

  // Rest of the Videos component
  // ... (Use your original Videos component code here)

  const setupSources = async () => {
    const localStream = await navigator.mediaDevices.getUserMedia({
      video: true,
      audio: true,
    });
    const remoteStream = new MediaStream();

    localStream.getTracks().forEach((track) => {
      pc.addTrack(track, localStream);
    });

    pc.ontrack = (event) => {
      event.streams[0].getTracks().forEach((track) => {
        remoteStream.addTrack(track);
      });
    };

    localRef.current.srcObject = localStream;
    remoteRef.current.srcObject = remoteStream;

    setWebcamActive(true);

    if (mode === "create") {
      const callRef = database.ref("calls/Abdullah"); // Create a new call reference

      pc.onicecandidate = (event) => {
        event.candidate &&
          callRef.child("offerCandidates").push(event.candidate.toJSON()); // Save ICE candidates
      };

      setRoomId(callRef.key);
      console.log(callRef.key);

      const offerDescription = await pc.createOffer();
      await pc.setLocalDescription(offerDescription);

      const offer = {
        sdp: offerDescription.sdp,
        type: offerDescription.type,
      };

      await callRef.set({ offer });

      callRef.child("answer").on("value", (snapshot) => {
        const data = snapshot.val();
        if (!pc.currentRemoteDescription && data) {
          const answerDescription = new RTCSessionDescription(data);
          pc.setRemoteDescription(answerDescription);
        }
      });

      callRef.child("answerCandidates").on("child_added", (snapshot) => {
        const candidate = new RTCIceCandidate(snapshot.val());
        pc.addIceCandidate(candidate);
      });
    } else if (mode === "join") {
      const callRef = database.ref("calls").child(callId); // Reference the specific call

      pc.onicecandidate = (event) => {
        event.candidate &&
          callRef.child("answerCandidates").push(event.candidate.toJSON()); // Save ICE candidates
      };

      const callData = await callRef
        .once("value")
        .then((snapshot) => snapshot.val());

      const offerDescription = callData.offer;
      await pc.setRemoteDescription(
        new RTCSessionDescription(offerDescription)
      );

      const answerDescription = await pc.createAnswer();
      await pc.setLocalDescription(answerDescription);

      const answer = {
        type: answerDescription.type,
        sdp: answerDescription.sdp,
      };

      await callRef.update({ answer });

      callRef.child("offerCandidates").on("child_added", (snapshot) => {
        const candidate = new RTCIceCandidate(snapshot.val());
        pc.addIceCandidate(candidate);
      });

      // if (roomId) {
      //   const roomRef = database.ref("calls").child(roomId);
      //   await roomRef.child("answerCandidates").remove();
      //   await roomRef.child("offerCandidates").remove();
      //   await roomRef.remove();
      // }
    }

    pc.onconnectionstatechange = (event) => {
      if (pc.connectionState === "disconnected") {
        hangUp();
      }
    };
  };

  const hangUp = async () => {
    pc.close();

    if (roomId) {
      const roomRef = database.ref("calls").child(roomId);
      await roomRef.child("answerCandidates").remove();
      await roomRef.child("offerCandidates").remove();
      await roomRef.remove();
    }

    window.location.reload();
  };

  return (
    <div className="videos">
      <video ref={localRef} autoPlay playsInline className="local" muted />
      <video ref={remoteRef} autoPlay playsInline className="remote" />

      <div className="buttonsContainer">
        <button
          onClick={hangUp}
          disabled={!webcamActive}
          className="hangup button"
        >
          <HangupIcon />
        </button>
        <div tabIndex={0} role="button" className="more button">
          <MoreIcon />
          <div className="popover">
            <button
              onClick={() => {
                navigator.clipboard.writeText(roomId);
              }}
            >
              <CopyIcon /> Copy joining code
            </button>
          </div>
        </div>
      </div>

      {!webcamActive && (
        <div className="modalContainer">
          <div className="modal">
            <h3>Turn on your camera and microphone and start the call</h3>
            <div className="container">
              <button onClick={() => setPage("home")} className="secondary">
                Cancel
              </button>
              <button onClick={setupSources}>Start</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Videos;
