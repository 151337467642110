import React, { useState } from "react";
import Menu from "./Menu";
import Videos from "./Videos";
import VideosAnsaer from "./VideosAnswer";
import VideosCaller from "./VideosCaller";
import firebase from "firebase/compat/app";
import "firebase/compat/database";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import "./App.css";

const firebaseConfig = {
  apiKey: "AIzaSyD7B2T_IFCgVCTCYSDhd19YPKVVd_nndKc",
  authDomain: "testing-7b74b.firebaseapp.com",
  databaseURL: "https://testing-7b74b-default-rtdb.firebaseio.com",
  projectId: "testing-7b74b",
  storageBucket: "testing-7b74b.appspot.com",
  messagingSenderId: "910591307387",
  appId: "1:910591307387:web:6405f4d2e91458f763fcde",
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

const App = () => {
  const [currentPage, setCurrentPage] = useState("home");
  const [joinCode, setJoinCode] = useState("");

  return (
    <Router>
      {/* <div className="app">
        {currentPage === "home" ? (
          <Menu
            joinCode={joinCode}
            setJoinCode={setJoinCode}
            setPage={setCurrentPage}
          />
        ) : (
          <Videos
            mode={currentPage}
            callId={joinCode}
            setPage={setCurrentPage}
          />
        )}
      </div> */}
      <Routes>
        <Route
          path="/"
          element={
            <div className="app">
              {currentPage === "home" ? (
                <Menu
                  joinCode={joinCode}
                  setJoinCode={setJoinCode}
                  setPage={setCurrentPage}
                />
              ) : (
                <Videos
                  mode={currentPage}
                  callId={joinCode}
                  setPage={setCurrentPage}
                />
              )}
            </div>
          }
        />

        {/* Only For Android App Use ⬇⬇⏬⏬📉📩👎⬇⬇*/}
        <Route path="answer/:joinCode" element={<VideosAnsaer />} />
        <Route path="caller/:joinCode" element={<VideosCaller />} />
        {/* Only For Android App Use ⬆⬆⬆👍👍⬆⬆⬆ */}
        <Route
          path="*"
          element={
            <div className="app">
              {currentPage === "home" ? (
                <Menu
                  joinCode={joinCode}
                  setJoinCode={setJoinCode}
                  setPage={setCurrentPage}
                />
              ) : (
                <Videos
                  mode={currentPage}
                  callId={joinCode}
                  setPage={setCurrentPage}
                />
              )}
            </div>
          }
        />
      </Routes>
    </Router>
  );
};

export default App;
